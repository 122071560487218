import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
    background-color: #333;
    color: #fff;
    padding: 2rem;
    text-align: center;
`;

const SocialIcons = styled.div`
    margin-top: 1rem;
    a {
        color: #fff;
        margin: 0 1rem;
        font-size: 1.5rem;
    }
`;

const Footer = () => {
  return (
    <footer style={{ background: '#333', color: 'white', padding: '20px', textAlign: 'center' }}>
      <p>
        © 2024 AgreementAi. All rights reserved.
      </p>
      <p>
        <Link to="/privacy-policy" style={{ color: 'white', marginRight: '10px', textDecoration: 'none' }}>
          Privacy Policy
        </Link>
        |
        <Link to="/terms-and-conditions" style={{ color: 'white', marginLeft: '10px', textDecoration: 'none' }}>
          Terms and Conditions
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
