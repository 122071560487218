import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import axios from "axios";
import { jsPDF } from "jspdf";
import showdown from 'showdown';
import AgreementPreview from "./AgreementPreview";
import { useNavigate } from 'react-router';
const apiKey = process.env.REACT_APP_OPEN_AI_KEY
import logger from "../utils/logger";
import { Helmet } from "react-helmet";

const apiUrl = process.env.REACT_APP_END_POINT


const AgreementForm = () => {
  const [agreementType, setAgreementType] = useState('nda');
  const [loading, setLoading] = useState(false);  
  const [form] = Form.useForm();
  const [generatedText, setGeneratedText] = useState("");
  const converter = new showdown.Converter();
  const navigate = useNavigate();

  useEffect(() => {
        logger.log("Updated generatedText:", generatedText);
    }, [generatedText]);


  // Agreement types and respective fields
  const agreementTypes = {
    nda: {
      title: 'Non-Disclosure Agreement (NDA)',
      fields: [
        { label: 'Name of Disclosing Party', name: 'disclosingParty' },
        { label: 'Name of Receiving Party', name: 'receivingParty' },
        { label: 'Purpose of Disclosure', name: 'purpose' },
        { label: 'Confidential Information', name: 'confidentialInfo' },
        { label: 'Duration of Confidentiality', name: 'duration' },
        { label: 'Governing Law', name: 'governingLaw' }
      ]
    },
    service: {
      title: 'Service Contract',
      fields: [
        { label: 'Service Provider Name', name: 'providerName' },
        { label: 'Client Name', name: 'clientName' },
        { label: 'Services to Be Provided', name: 'servicesProvided' },
        { label: 'Payment Terms', name: 'paymentTerms' },
        { label: 'Duration of Service', name: 'duration' },
        { label: 'Confidentiality Clauses', name: 'confidentiality' },
        { label: 'Termination Conditions', name: 'termination' }
      ]
    },
    employment: {
      title: 'Employment Contract',
      fields: [
        { label: 'Employee Name', name: 'employeeName' },
        { label: 'Employer Name', name: 'employerName' },
        { label: 'Job Title and Description', name: 'jobTitle' },
        { label: 'Compensation and Benefits', name: 'compensation' },
        { label: 'Working Hours and Schedule', name: 'workingHours' },
        { label: 'Employment Term', name: 'employmentTerm' },
        { label: 'Termination Conditions', name: 'terminationConditions' }
      ]
    },
    partnership: {
      title: 'Partnership Agreement',
      fields: [
        { label: 'Partner Names', name: 'partnerNames' },
        { label: 'Purpose of the Partnership', name: 'partnershipPurpose' },
        { label: 'Contribution of Each Partner', name: 'contribution' },
        { label: 'Profit and Loss Distribution', name: 'profitLoss' },
        { label: 'Decision-Making Process', name: 'decisionMaking' },
        { label: 'Duration and Termination Conditions', name: 'durationTermination' }
      ]
    },
    loan: {
      title: 'Loan Agreement',
      fields: [
        { label: 'Lender Name', name: 'lenderName' },
        { label: 'Borrower Name', name: 'borrowerName' },
        { label: 'Loan Amount', name: 'loanAmount' },
        { label: 'Interest Rate', name: 'interestRate' },
        { label: 'Repayment Terms and Schedule', name: 'repaymentTerms' },
        { label: 'Collateral', name: 'collateral' },
        { label: 'Late Payment Fees', name: 'latePaymentFees' }
      ]
    },
    rent: {
      title: 'Rent Agreement',
      fields: [
        { label: 'Landlord Name', name: 'landlordName' },
        { label: 'Tenant Name', name: 'tenantName' },
        { label: 'Property Address', name: 'propertyAddress' },
        { label: 'Rent Amount and Due Dates', name: 'rentAmount' },
        { label: 'Lease Duration', name: 'leaseDuration' },
        { label: 'Maintenance Responsibilities', name: 'maintenanceResponsibilities' },
        { label: 'Security Deposit', name: 'securityDeposit' }
      ]
    },
    sales: {
      title: 'Sales Contract',
      fields: [
        { label: 'Buyer Name', name: 'buyerName' },
        { label: 'Seller Name', name: 'sellerName' },
        { label: 'Product/Service Description', name: 'productDescription' },
        { label: 'Sale Price', name: 'salePrice' },
        { label: 'Delivery Terms', name: 'deliveryTerms' },
        { label: 'Payment Terms', name: 'paymentTerms' },
        { label: 'Warranty Terms', name: 'warrantyTerms' }
      ]
    },
    freelance: {
    title: 'Freelance Agreement',
    fields: [
      { label: 'Freelancer Name', name: 'freelancerName' },
      { label: 'Client Name', name: 'clientName' },
      { label: 'Services Provided', name: 'servicesProvided' },
      { label: 'Compensation Amount', name: 'compensationAmount' },
      { label: 'Payment Schedule', name: 'paymentSchedule' },
      { label: 'Intellectual Property Ownership', name: 'intellectualProperty' },
      { label: 'Confidentiality Obligations', name: 'confidentialityObligations' },
      { label: 'Termination Terms', name: 'terminationTerms' },
      { label: 'Governing Law', name: 'governingLaw' }
    ]
  }
  };

  const generatePDF = () => {

        // Step 1: Convert Markdown to HTML
        const htmlContent = converter.makeHtml(generatedText);  // Convert markdown to HTML

        // Step 2: Create a PDF from HTML content
        const doc = new jsPDF();
        logger.log("generatePDF",agreementType)

        // Step 3: Add HTML to PDF
        doc.html(htmlContent, {
            callback: function (doc) {
                // Save the generated PDF
                doc.save(`${agreementType}_Agreement.pdf`);
            },
            x: 10, // Margin of 10 on the left
            y: 10, // Margin of 10 on top
            width: 180,  // Width of the content
            windowWidth: 800, // Adjust to the window's width if necessary
        });
        navigate('/success');
    };


  // Function to handle form submission (logging the prompt)
  const handleSubmit = async() => {
    setLoading(true);
    const formData = form.getFieldsValue();
    const prompt = buildPrompt(agreementTypes[agreementType].fields, formData);
    try {
        const response = await fetch(`${apiUrl}/generate-agreemnt`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: prompt }),
          });
        // const response = await axios.post('https://api.openai.com/v1/chat/completions', 
        // {
        //     model: "gpt-4o-mini",  // Replace with the correct model
        //     messages: [{"role": "user", "content": `${prompt} +  Make sure the tone is Professional.`}]
        // },
        // {
        //     headers: {
        //         Authorization: `Bearer ${apiKey}`,
        //     },
        // });

        const { agreement } = await response.json();
        logger.log("Response: ",agreement)
        setGeneratedText(agreement);
        // generatePDF(agreementType,agreementText)
    } catch (error) {
        logger.error('Error generating agreement:', error);
    } finally {
        setLoading(false);
    }

  };

  // Function to build prompt from form data
  const buildPrompt = (fields, formData) => {
    let prompt = `Create a detailed ${agreementTypes[agreementType].title} with the following details: `;
    fields.forEach(field => {
      prompt += `${field.label}: ${formData[field.name]}, `;
    });
    return prompt.slice(0, -2); // Remove the last comma
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <Helmet>
            <h1>{agreementTypes[agreementType].title}</h1>
            <meta name="description" content="Fill out our streamlined agreement form to create customized contracts effortlessly. AgreementAI ensures secure and efficient contract creation tailored to your needs." />
            <meta name="keywords" content="contract form, create contracts, online contracts, AgreementAI, customize contracts" />
            <meta name="author" content="AgreementAI" />
            <meta property="og:title" content="Contract Form - Create and Manage Agreements Easily" />
            <meta property="og:description" content="Use AgreementAI to create contracts effortlessly with our easy-to-use online form. Secure, fast, and tailored to your requirements." />
            <meta property="og:url" content="https://agreementai.in/contract" />
            <meta property="og:type" content="website" />
            <meta name="robots" content="index, follow" />
        </Helmet>    
      <Form form={form} layout="vertical">
        <Form.Item label="Agreement Type">
          <Select
            defaultValue={agreementType}
            onChange={(value) => setAgreementType(value)}
          >
            {Object.keys(agreementTypes).map((key) => (
              <Select.Option key={key} value={key}>
                {agreementTypes[key].title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {agreementTypes[agreementType].fields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={[{ required: true, message: `Please input ${field.label}!` }]}
          >
            <Input placeholder={`Enter ${field.label}`} />
          </Form.Item>
        ))}

        <Button type="primary" onClick={handleSubmit} loading={loading}>
          Generate Document
        </Button>
      </Form>
      {/* Agreement Preview */}
      <div style={{ marginTop: "20px" }}>
        {generatedText ? (
                <AgreementPreview agreementType={agreementType} agreementText={converter.makeHtml(generatedText)} generatePdf={generatePDF} />
            ) : (
                <p>Please generate the agreement to see the preview.</p>
            )}
      </div>
    </div>
  );
};

export default AgreementForm;
