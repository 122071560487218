// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { FaPlus, FaMinus } from 'react-icons/fa';

// const FAQContainer = styled.section`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 4rem 2rem;
//     background-color: #f4f4f4;
//     text-align: center;
//     font-family: 'Sora', sans-serif;
// `;

// const FAQTitle = styled.h2`
//     font-size: 2.5rem;
//     line-height: 2.5rem;
//     margin-bottom: 2rem;
//     color: #091747;
//     text-align: center;
// `;

// const FAQList = styled.div`
//     text-align: left;
//     width: 100%;
//     max-width: 800px;
// `;

// const FAQSection = styled.div`
//     margin-bottom: 2rem;
// `;

// const FAQSectionTitle = styled.h3`
//     font-size: 1.5rem;
//     margin-bottom: 1rem;
//     color: #091747;
// `;

// const FAQItem = styled.div`
//     margin-bottom: 1rem;
// `;

// const FAQQuestion = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     cursor: pointer;
//     font-size: 1.1rem;
//     color: #000;
//     font-weight: bold;
// `;

// const FAQAnswer = styled.div`
//     margin-top: 1rem;
//     font-size: 1rem;
//     color: #333;
//     max-height: 0;
//     overflow: hidden;
//     transition: max-height 0.3s ease;
// `;

// const IconCircle = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 24px;
//     height: 24px;
//     border-radius: 50%;
//     background-color: #091747;
//     color: #fff;
// `;

// const FAQ = () => {
//     const [activeIndex, setActiveIndex] = useState(null);

//     const faqs = [
//         {
//             section: 'About the Platform',
//             questions: [
//                 {
//                     question: 'What does this platform do?',
//                     answer: 'Quickly generates simple agreements like NDAs or rental contracts.'
//                 },
//                 {
//                     question: 'Are these agreements legally binding?',
//                     answer: 'These are templates for informational purposes. Consult a legal professional for compliance.'
//                 }
//             ]
//         },
//         {
//             section: 'Usage',
//             questions: [
//                 {
//                     question: 'How do I generate an agreement?',
//                     answer: 'Choose an agreement, fill in details, and generate instantly.'
//                 },
//                 {
//                     question: 'Do I need an account?',
//                     answer: 'No, it’s accessible to everyone without signup.'
//                 }
//             ]
//         },
//         {
//             section: 'Pricing & Payments',
//             questions: [
//                 {
//                     question: 'Is this service free?',
//                     answer: 'A small fee applies per agreement.'
//                 },
//                 {
//                     question: 'What payment methods are accepted?',
//                     answer: 'Payments via credit/debit cards are supported.'
//                 }
//             ]
//         },
//         {
//             section: 'Support',
//             questions: [
//                 {
//                     question: 'Do you save my data?',
//                     answer: 'No, we do not store any personal or document data.'
//                 }
//             ]
//         }
//     ];

//     const toggleFAQ = (index) => {
//         setActiveIndex(activeIndex === index ? null : index);
//     };

//     return (
//         <FAQContainer>
//             <FAQTitle>
//                 Frequently <br /> Asked <br /> Questions
//             </FAQTitle>
//             <FAQList>
//                 {faqs.map((section, sectionIndex) => (
//                     <FAQSection key={sectionIndex}>
//                         <FAQSectionTitle>{section.section}</FAQSectionTitle>
//                         {section.questions.map((faq, index) => (
//                             <FAQItem key={index}>
//                                 <FAQQuestion onClick={() => toggleFAQ(sectionIndex * 10 + index)}>
//                                     <span>{faq.question}</span>
//                                     <IconCircle>
//                                         {activeIndex === sectionIndex * 10 + index ? <FaMinus /> : <FaPlus />}
//                                     </IconCircle>
//                                 </FAQQuestion>
//                                 <FAQAnswer style={{ maxHeight: activeIndex === sectionIndex * 10 + index ? '100px' : '0' }}>
//                                     {faq.answer}
//                                 </FAQAnswer>
//                             </FAQItem>
//                         ))}
//                     </FAQSection>
//                 ))}
//             </FAQList>
//         </FAQContainer>
//     );
// };
import React, { useState } from 'react';
import { Typography, Collapse } from 'antd';

const { Title } = Typography;
const { Panel } = Collapse;

const FAQSection = () => {
  const [activeKey, setActiveKey] = useState(null);

  const faqs = [
    {
      question: 'What types of agreements can I generate?',
      answer: 'You can generate simple agreements such as NDAs, partnership agreements, and rental contracts. More types will be added soon!',
    },
    {
      question: 'Do I need an account to use this platform?',
      answer: 'No, you can generate and download agreements without signing up.',
    },
    {
      question: 'How much does it cost to download an agreement?',
      answer: 'The cost per agreement is only $1.17/₹99, this is just to cover our service cost.',
    },
    {
      question: 'Is my data safe on this platform?',
      answer: 'Yes, we prioritize your privacy. No personal data is stored on our servers.',
    },
    {
      question: 'What if I’m not satisfied with the generated agreement?',
      answer: 'If the agreement doesn’t meet your expectations, please contact support for assistance.',
    },
    {
      question: 'Are the agreements generated using AgreementAI legally binding?',
      answer: 'No, the agreements generated by AgreementAI are not legally binding. The platform is designed to assist in content generation, providing draft agreement templates based on the inputs provided by the user. These drafts should always be reviewed, customized, and approved by a qualified legal professional to ensure they meet legal standards and are enforceable under applicable laws.',
    }
  ];

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div style={{ padding: '30px 20px', fontFamily: 'Sora, sans-serif', backgroundColor: '#f9f9f9' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {/* Title Section */}
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Title
            level={2}
            style={{
              fontSize: '28px',
              color: '#001529',
              lineHeight: '40px',
              wordWrap: 'break-word',
              margin: '0 auto',
            }}
          >
            Frequently <br /> Asked <br /> Questions
          </Title>
        </div>

        {/* FAQ Section */}
        <div>
          <Collapse
            accordion
            activeKey={activeKey}
            onChange={handleToggle}
            bordered={false}
            style={{
              backgroundColor: 'transparent',
            }}
          >
            {faqs.map((faq, index) => (
              <Panel
                key={index}
                header={
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#333',
                    }}
                  >
                    {faq.question}
                  </div>
                }
                style={{
                  marginBottom: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#ffffff',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <p style={{ margin: 0, fontWeight: 'normal', color: '#595959' }}>{faq.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;