import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";
import PaymentButton from './PaymentButton';
import logger from "../utils/logger";

const { Paragraph, Text } = Typography;

const AgreementPreview = ({ agreementType = "",  agreementText = "", generatePdf }) => {

  const [paymentStatus, setPaymentStatus] = useState(null);

  const handlePaymentSuccess = (order_id) => {
    logger.log('Payment Details:', order_id);
    setIsPaid(true);
    setPaymentStatus('success');
    generatePdf(agreementText);
  };

  const handlePaymentFailed = (status) => {
    setPaymentStatus(status);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const previewLength = 500; // Show first 200 characters
  const [isPaid, setIsPaid] = useState(false);

  const showPreview = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  logger.log('Generated text:' ,agreementText)
  // Safely handle the preview text
  const previewText = agreementText.length > previewLength 
    ? `${agreementText.substring(0, previewLength)}...` 
    : agreementText;

  return (
    <div style={{ marginTop: "20px" }}>
      <Typography>
        <Text strong style={{ fontSize: "16px" }}>
          Agreement Preview:
        </Text>
        <div
          className="preview"
          dangerouslySetInnerHTML={{ __html: previewText }}
        />
      </Typography>
      <Button type="primary" onClick={showPreview}>
        Show Full Document
      </Button>

      {/* Modal for Payment */}
      <Modal
        title="Access Full Document"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <PaymentButton agreementType={agreementType} onSuccess={handlePaymentSuccess} onFailure={handlePaymentFailed} />,
        ]}
      >
        <p>
          To access the full agreement document, please complete the payment
          process. Once payment is confirmed, you'll be able to download the
          complete agreement.
        </p>
        <p>
          {paymentStatus === 'failed' && <p style={{ color: 'red' }}>Payment failed. Please try again.</p>}
          {paymentStatus === 'unknown' && 
          <p style={{ color: 'black', fontSize: '16px' }}>
            Your payment couldn't be processed, if money has been debited then please{' '}
            <span
              onClick={handlePaymentSuccess}
              style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
            >
              download manually
            </span>
            , if not please retry again with new payment.
          </p>
      }
        </p>
      </Modal>
    </div>
  );
};

export default AgreementPreview;
