import React from 'react';
import ContactForm from '../components/ContactForm';
import styled from 'styled-components';

const ContactContainer = styled.div`
    padding: 2rem;
    text-align: center;
    background-color: #f4f4f4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ContactPage = () => {
    return (
        <ContactContainer>
            <h1>Get in Touch</h1>
            <ContactForm />
        </ContactContainer>
    );
};

export default ContactPage;
