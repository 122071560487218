const logger = {
    log: (...args) => {
        if (process.env.REACT_APP_ENV === "development") {
            console.log(...args);
        }
    },
    warn: (...args) => {
        if (process.env.REACT_APP_ENV === "development") {
            console.warn(...args);
        }
    },
    error: (...args) => {
        if (process.env.REACT_APP_ENV === "development") {
            console.error(...args); // Always log errors regardless of environment
        }
    },
};

export default logger;
