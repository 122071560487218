import React from 'react';
import { Typography, Row, Col, Card } from 'antd';

const { Title, Text } = Typography;

const KeyFeatures = () => {
  const features = [
    {
      title: 'Fast Document Generation',
      description: 'Generate professional agreements in just a few seconds with minimal input.',
    },
    {
      title: 'Easy-to-Use Interface',
      description: 'A user-friendly platform designed for simplicity and efficiency.',
    },
    {
      title: 'No Signup Required',
      description: 'Access and download documents without creating an account.',
    },
    {
      title: 'Secure Transactions',
      description: 'Integrated with trusted payment gateways for secure and smooth transactions.',
    },
    {
      title: 'Affordable Pricing',
      description: 'Transparent pricing that suits businesses of all sizes.',
    },
  ];

  return (
    <div
      style={{
        padding: '50px',
        backgroundColor: '#ffffff', // White background for a clean look
      }}
    >
      <Title
        level={2}
        style={{
          textAlign: 'center',
          marginBottom: '40px',
          color: '#001529', // Consistent primary color
        }}
      >
        Key Features
      </Title>
      <Row gutter={[16, 16]} justify="center">
        {features.map((feature, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              style={{
                borderRadius: '10px', // Rounded edges for modern look
                textAlign: 'center',
                height: '100%',
              }}
            >
              <Title
                level={4}
                style={{
                  color: '#001529',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {feature.title}
              </Title>
              <Text style={{ color: '#595959' }}>{feature.description}</Text>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default KeyFeatures;
