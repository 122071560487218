import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AgreementPage from './pages/AgreementPage';
import ContactPage from './pages/ContactPage';
import Header from './components/Header';
import SuccessPage from './pages/SuccessPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import GlobalStyle from './styles';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const tagManagerArgs = {
    gtmId: 'G-C12G4P7C10', // Replace with your Measurement ID
};

TagManager.initialize(tagManagerArgs);

function App() {
    const location = useLocation();

    useEffect(() => {
        window.gtag('config', 'G-C12G4P7C10', {
            page_path: location.pathname,
        });
    }, [location]);
    return (
        <div>
            <GlobalStyle />
            <Header />
            <Routes>
                <Route path="/" exact element={ <HomePage/>} />
                <Route path="/generate-contract" element={<AgreementPage/>} />
                <Route path="/contact" element={<ContactPage/>} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
            </Routes>
        </div>
    );
}

export default App;
