import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.svg'; // Make sure you have a logo image in the assets folder

const HeaderContainer = styled.header`
    background-color: #fff;
    color: #091747;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoImage = styled.img`
    height: 40px;
    margin-right: 1rem;
`;

const LogoText = styled.h1`
    font-size: 1.5rem;
    margin: 0;
`;

const Nav = styled.nav`
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 1rem;
    }
    li {
        display: inline;
    }
    a {
        color: #091747;
        text-decoration: none;
        font-size: 1rem;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const Header = () => {
    return (
        <HeaderContainer>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <LogoContainer>
                <LogoText>Agreement</LogoText>
                <LogoImage src={logo} alt="AgreementAi Logo" />
            </LogoContainer>
            </Link>
            <Nav>
                <ul>
                    <li><Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }} >Contact</Link></li>
                </ul>
            </Nav>
        </HeaderContainer>
    );
};

export default Header;
