import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";
import logger from "../utils/logger";
const apiUrl = process.env.REACT_APP_END_POINT


const verifyPayment = async ({order_id, onSuccess, onFailure}) => {

    const response = await fetch(`${apiUrl}/cf-verify-payment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderId : order_id
        }),
      });
    const { success, message } = await response.json();

    if (success === true) {
       logger.log('Payment verified successfully');
        onSuccess(order_id)
    } else {
        logger.error('Payment verification failed');
        onFailure()
    }

}

const PaymentButton = ( { agreementType, onSuccess, onFailure} ) => {
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    // Fetch the order details
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/cf-create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ agreementType : agreementType }),
      });
      const { order_id, order_amount, order_currency, payment_session_id } = await response.json();
      // const options = {
      //   key: 'rzp_test_afrNRKCNVXzwEH',
      //   amount: amount,
      //   currency: currency,
      //   order_id: id,
      //   name: 'AgreementAi',
      //   description: `Agreement for ${agreementType}`,
      //   handler: function (response) {
      //     setLoading(false);
      //     onSuccess({paymentResponse: response, onSuccess: onSuccess, onFailure: onFailure});
      //   },
      //   prefill: {
      //     name: 'AgreementAi Customer',
      //     email: 'john.doe@example.com',
      //   },
      // };

      // const rzp = new Razorpay(options);
      // rzp.open();
      // razorpay.on('payment.failed', (response) => {
      //   console.error('Payment failed:', response.error);
      //   alert('Payment failed. Please try again.');
      //   onFailure()
      // });
      const cashfree = Cashfree({
                mode: `${process.env.REACT_APP_CF_ENV}`,
      });
      let checkoutOptions = {
          paymentSessionId: payment_session_id,
          redirectTarget: "_modal",
      };
      cashfree.checkout(checkoutOptions).then((result) => {
        if(result.error){
          // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
          logger.log("User has closed the popup or there is some payment error, Check for Payment Status");
          logger.log(result.error);
          onFailure();
        }
        if(result.redirect){
          // This will be true when the payment redirection page couldnt be opened in the same window
          // This is an exceptional case only when the page is opened inside an inAppBrowser
          // In this case the customer will be redirected to return url once payment is completed
          logger.log("Payment will be redirected");
          onFailure('unknown');
        }
        if(result.paymentDetails){
          // This will be called whenever the payment is completed irrespective of transaction status
          logger.log("Payment has been completed, Check for Payment Status",order_id);
          logger.log(result.paymentDetails);
          verifyPayment( { order_id: order_id,  onSuccess: onSuccess,onFailure: onFailure } )
        }
      });
    } catch (error) {
      onFailure()
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={handlePayment} loading={loading}>
      Pay to Download Full Agreement
    </Button>
  );
};

export default PaymentButton;
