import React from 'react';
import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;
import { Helmet } from "react-helmet";

const HeroSection = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '80vh',
        backgroundColor: '#f0f2f5', // Light background color for a clean look
        padding: '20px',
      }}
    >
    <Helmet>
      <Title
        level={1}
        style={{
          fontWeight: 'bold',
          color: '#001529', // Primary dark blue color for the title
          marginBottom: '20px',
        }}
      >
      Focus on Business, Leave Documents to Us
      </Title>
      <meta
          name="description"
          content="AgreementAI helps streamline contracts and agreements with intuitive tools and seamless payments. Get started today!"
        />
        <meta name="keywords" content="contracts, agreements, ai agreement, automation, AgreementAI, ai contracts" />
     </Helmet> 
      <Text
        style={{
          fontSize: '18px',
          color: '#595959', // Subtle gray color for the subtitle
          maxWidth: '600px',
          lineHeight: '1.6',
          marginBottom: '30px',
        }}
      >
        Effortlessly generate professional agreements in seconds. Save time and
        focus on what matters most—your business.
      </Text>
      <Link to="/generate-contract">
      <Button
        type="primary"
        size="large"
        style={{
          padding: '10px 30px',
          borderRadius: '8px', // Rounded button for a modern feel
          fontSize: '16px',
        }}
      >
        Generate Now
      </Button>
      </Link>
    </div>
  );
};

export default HeroSection;
