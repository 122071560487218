import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>By using AgreementAi’s services, you agree to the following terms and conditions. Please read them carefully before using our services.</p>

      <h2>Use of Our Services</h2>
      <p>AgreementAi provides a platform for generating documents. By using our website, you agree to:
        <ul>
          <li>Provide accurate details.</li>
          <li>Use our services only for lawful purposes.</li>
        </ul>
      </p>

      <h2>Account Creation</h2>
      <p>To use our services, you may need to create an account, which is solely for the purpose of processing and verifying orders. You are responsible for maintaining the confidentiality of your account credentials.</p>

      <h2>Payments</h2>
      <p>We use secure third-party payment gateways like Cashfree to process payments. By making a payment, you agree to their terms and conditions.</p>

      <h2>Limitation of Liability</h2>
      <p>AgreementAi is not responsible for any indirect or consequential damages resulting from the use of our services. We do not guarantee the availability or uninterrupted access to our website.</p>

      <h2>Termination</h2>
      <p>We reserve the right to suspend or terminate access to our services at any time, especially if there is any violation of these Terms and Conditions.</p>

      <h2>Governing Law</h2>
      <p>These Terms and Conditions are governed by the laws applicable to our business operations.</p>

      <h2>Changes to Terms</h2>
      <p>We may update these terms periodically. Any changes will be reflected on this page, with an updated effective date.</p>

      <h2>Contact Information</h2>
      <p>If you have any questions regarding our Terms and Conditions, please contact us at <a href="mailto:minbartechnologies@gmail.com">minbartechnologies@gmail.com</a>.</p>
    </div>
  );
};

export default TermsAndConditions;
