import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>At AgreementAi, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our services.</p>

      <h2>Information We Collect</h2>
      <p>We do not store any personal information on our backend. We only store order-related data required for payment processing and order verification. No personal details are stored on our systems.</p>

      <h2>How We Use Your Information</h2>
      <p>We use the information collected solely for:
        <ul>
          <li>Processing payments</li>
          <li>Verifying orders</li>
          <li>Communicating with you about your order status</li>
        </ul>
      </p>

      <h2>Data Security</h2>
      <p>We implement appropriate security measures to protect the order data that we store. We do not store sensitive personal information.</p>

      <h2>Your Rights</h2>
      <p>As we do not store any personal information, there is no need for access or deletion requests. However, if you have any concerns, please feel free to reach out to us.</p>

      <h2>Contact Information</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:minbartechnologies@gmail.com">minbartechnologies@gmail.com</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;
