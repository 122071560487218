import React, { useState } from 'react';
import AgreementForm from '../components/AgreementForm';
import axios from 'axios';

const AgreementPage = () => {
    const [preview, setPreview] = useState('');

    const handleFormSubmit = async (data) => {
        try {
            const response = await axios.post('/api/agreement/preview', data);
            setPreview(response.data.preview);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGenerateFull = async () => {
    };

    return (
        <div>
            <AgreementForm  />
            {preview && (
                <div>
                    <h3>Preview</h3>
                    <pre>{preview}</pre>
                    <button onClick={handleGenerateFull}>Generate Full Document</button>
                </div>
            )}
        </div>
    );
};

export default AgreementPage;
